import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div className="App-title">Charles Alexander "Xan" Coronado</div>
          <div className="App-subtitle">May 6, 2008 ~ October 4, 2022 (age 14)</div>
      </header>
    </div>
  );
}

export default App;
